<template>
  <div class="col-lg-12 col-12">
    <div class="rounded shadow p-4" v-if="policyNumber == ''">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="mb-0">Métodos de pago:</h5>
        <div class="mb-1 row p-3">
          <!-- <div>
            <img
              src="images/payments/master-card.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/paypal.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/visa.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div>
          <div class="pl-3">
            <img
              src="images/payments/american-ex.png"
              height="25"
              alt=""
              class="text-right"
            />
          </div> -->
        </div>
        <div class="mb-1">
          <div>
            <!-- <b-button class="btn btn-md btn-primary" @click="showModal()"
              >Añadir +</b-button
            > -->
            <b-modal ref="my-modal" size="lg" title="Selecciona tu método de pago" no-stacking ok-only hide-footer>
              <div class="row">
                <div class="master-id col-md-6 mt-4 pt-2">
                  <div @click="setMaster()" class="card rounded shadow bg-secondary border-0">
                    <div class="card-body">
                      <img src="images/payments/costcoCards/master.png" height="40" alt="" class="text-right" />
                      <div class="mt-4">
                        <h5 class="text-light">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-light mb-0">Cristino Murfy</p>
                          <h6 class="mb-0 text-light">
                            Exp: <span class="text-light">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="visa-id col-md-6 mt-4 pt-2">
                  <div @click="setVisa()" class="card rounded shadow bg-dark border-0">
                    <div class="card-body">
                      <img src="images/payments/costcoCards/visaa.png" height="40" alt="" class="text-right" />
                      <div class="mt-4">
                        <h5 class="text-light">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-muted mb-0">Calvin Carlo</p>
                          <h6 class="mb-0 text-muted">
                            Exp: <span class="text-muted">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="american-id col-md-6 mt-4 pt-2">
                  <div @click="setAmerican()" class="card rounded shadow bg-light border-0">
                    <div class="card-body">
                      <img src="images/payments/costcoCards/americanEx.png" height="80" alt="" class="text-right" />
                      <div class="mt-1">
                        <h5 class="text-muted">•••• •••• •••• XXXX</h5>
                        <div class="d-flex justify-content-between">
                          <p class="h6 text-muted mb-0">Calvin Carlo</p>
                          <h6 class="mb-0 text-muted">
                            Exp: <span class="text-muted">XX/XX</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="paypal-id col-md-6 mt-4 pt-2">
                  <div @click="setPaypal()" class="card rounded shadow bg-light border-0">
                    <div class="card-body">
                      <img src="images/payments/costcoCards/paypals.png" height="40" alt="" class="text-right" />
                      <div class="mt-4">
                        <form>
                          <div class="form-group mt-4 pt-3 mb-0">
                            <div class="input-group mt-4">
                              <input name="email" id="email" type="email" placeholder="Paypal Email :" required="required"
                                class="form-control" />
                              <div class="input-group-append">
                                <button type="submit" id="paypalmail" class="btn btn-primary submitBnt">
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body rounded mt-2" v-if="policyNumber == ''">
      <div v-if="bankId === 'card'">
        <div class="row">
          <div class="col-2"></div>
          <div class="position-relative align-items-center col-md-8">
            <div class="card p-3 mb-5 bg-dark rounded border-0">
              <div class="card-body">
                <!-- <img
                  src="images/payments/costcoCards/master.png"
                  height="40"
                  alt=""
                  class="text-right"
                /> -->
                <div class="mt-2">
                  <div class="row">
                    <div class="col-8">
                      <label class="text-muted font-weight-normal" for="">Número Tarjeta</label>
                      <div v-if="form.number != ''">
                        <h5 class="text-light">
                          <!-- {{ cardNum($v.form.number.$model) }} -->
                          #### #### #### ####
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">#### #### #### ####</h5>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="text-muted font-weight-normal" for="">CVV</label>
                      <div v-if="form.cvc != ''">
                        <h5 class="text-light">
                          ####
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">####</h5>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <label class="text-muted font-weight-normal" for="">Nombre Titular</label>
                      <div v-if="form.cardholder != ''">
                        <h5 class="text-light">
                          {{ $v.form.cardholder.$model }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">XXXXXXXXXXX</h5>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="fex text-muted font-weight-normal" for="">Fecha de Expiración</label>
                      <div v-if="form.month != '' || form.year != ''">
                        <h5 class="text-light">
                          {{ $v.form.month.$model }} / {{ $v.form.year.$model }}
                        </h5>
                      </div>
                      <div v-else>
                        <h5 class="text-muted">01/01</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>

        <div class="row">
          <form id="formulario" class="col-md-8 mt-3 pt-3 text-primary container">
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Titular:</label>
                  <div class="position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-user">
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <input v-model="$v.form.cardholder.$model" id="cardholder" type="text"
                      placeholder="Ingrese su nombre :" class="form-control pl-5" name="cardholder" />
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.cardholder.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Número de tarjeta:</label>
                  <div class="position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-credit-card">
                      <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg>

                    <input v-model="$v.form.number.$model" name="number" id="last" type="number"
                      placeholder="Número de tarjeta:" class="form-control pl-5" v-on:keypress="isNumber(event)"
                      maxlength="16" />
                    <i class="fas fa-user"></i>
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.number.$error }">
                      <span>ingrese el número de su tarjeta correctamente</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Mes de expiración:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select v-model="$v.form.month.$model" :options="optionsMonth" value-field="value"
                        text-field="id" disabled-field="notEnabled"></b-form-select>
                    </div>
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.month.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Año de expiración:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select v-model="$v.form.year.$model" :options="optionsYear" value-field="value"
                        text-field="id" disabled-field="notEnabled"></b-form-select>
                    </div>
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.year.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>CVV:</label>
                  <div class="position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="fea icon-sm icons feather feather-credit-card">
                      <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg><input v-model="$v.form.cvc.$model" name="cvc" id="cvc" placeholder="CVV"
                      class="form-control pl-5" maxlength="4" type="password" v-on:keypress="isNumber(event)" />
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.cvc.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cuotas:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select v-model="$v.form.dues.$model" :options="optionsDues" value-field="value"
                        text-field="id" disabled-field="notEnabled"></b-form-select>
                    </div>
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.dues.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="form.dues == 24">
                <div class="form-group">
                  <label>Meses sin intereses:</label>
                  <div class="position-relative">
                    <div>
                      <b-form-select v-model="$v.form.duesSM.$model" :options="optionsDuesSM" value-field="value"
                        text-field="id" disabled-field="notEnabled"></b-form-select>
                    </div>
                    <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.duesSM.$error }">
                      <span>Proporcione un valor válido</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-12 text-center">
          <a-button @click="send()" class="btn col-md-6 btn-primary mt-4" v-bind:disabled="disabled" :loading="loading">
            <span v-if="disabled === true" class="mr-3 spinner-grow spinner-grow-sm" role="status"
              aria-hidden="true"></span>
            <span class="sr-only"></span>
            <span style="padding-left: 5px;">Pagar</span>
          </a-button>

          <br>
          <br>
          <br>



          <p class="text-center" v-show="form.dues == 24">
            <img src="/images/payments/image018.png" class="p-3" style="max-width: 200px" alt="">
            <img src="/images/payments/image019.png" class="p-3" style="max-width: 200px" alt="">
            <img src="/images/payments/image020.png" class="p-3" style="max-width: 200px" alt="">
            <img src="/images/payments/image021.png" class="p-3" style="max-width: 200px" alt="">
          </p>


        </div>
      </div>
    </div>


    <a-modal v-model="visible" title="Gracias por su compra" :closable="false" :maskClosable="false">


      <template slot="footer">
        <p class="text-center">
          <a-button key="submit" class="btn btn-primary btn-block" type="primary" @click="handleOk">
            Cerrar
          </a-button>
        </p>
      </template>

      <p class="text-center">Tu pago fue recibido exitosamente, en unos minutos recibirás tu póliza número <b>{{
        policyNumber }}</b> en tu correo electrónico.</p>
      <p class="text-center">¡Gracias por tu confianza en elegir AIG!</p>
      <!-- <p class="text-center"><a href="javascript:void()" @click="activeService()" target="_blank">Activar servicio</a></p> -->
      <!-- <p class="text-center">
        <img style="max-width: 300px" src="https://firebasestorage.googleapis.com/v0/b/aig-empleados-m.appspot.com/o/logo-Tecnotrust-vertical-color.png?alt=media&token=dca785b2-0028-46c9-a02f-cc8e343c63e9" alt="">
      </p> -->
    </a-modal>


  </div>
</template>

 

<script>
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  mixins: [validationMixin],
  data() {
    return {
      disabled: false,
      bankId: "card",
      visible: false,
      form: {
        cardholder: "",
        number: "",
        month: null,
        year: null,
        cvc: "",
        dues: null,
        duesSM: null
      },
      complete: false,
      loading: false,
      policyNumber: '',

      year: "1",
      optionsYear: [],
      month: "1",
      optionsMonth: [],
      dues: "1",
      optionsDues: [
        { value: null, id: "Seleccione opción" },
        { value: 1, id: "Anual" },
        { value: 2, id: "Semestral" },
        { value: 4, id: "Trimestral" },
        { value: 12, id: "Mensual" },
        { value: 24, id: "Meses sin intereses" },
      ],
      optionsDuesSM: [
        { value: null, id: "Seleccione opción" },
        { value: 2, id: "3 Meses sin intereses" },
        { value: 3, id: "6 Meses sin intereses" },
        { value: 4, id: "9 Meses sin intereses" },
        { value: 5, id: "12 Meses sin intereses" },
      ],
    };
  },

  validations: {
    form: {
      cardholder: { required },
      number: { required },
      month: { required, numeric },
      year: { required, numeric },
      cvc: { required, numeric },
      dues: { required, numeric },
      duesSM: {},
    },
  },

  methods: {

    activeService() {



      this.$gtag.event('active_service', {
        'event_category': 'active_service',
        'event_label': this.policyNumber,
        'value': 1,

      })

      window.location.href = "https://suscripcion.tecnotrust.com/";

      this.$router.push({
        name: "Home",
      });
    },

    handleOk() {
      this.$gtag.event("active_service", {
        event_category: "active_service",
        event_label: this.policyNumber,
        value: 1,
      });

      //window.open('https://suscripcion.tecnotrust.com/', '_blank')

      this.$router.push({
        name: "Home",
      });
      //  this.$router.push({
      //       name: "Home",
      //     });
    },

    afterCloseModal() {
      this.$router.push({
        name: "Home",
      });
    },
    cardNum(str) {
      var long = str.length;
      var blankCount = 0;

      if (long <= 4) {
        blankCount = 0;
      } else {
        blankCount = long % 4 > 0 ? long / 4 : str.length / 4 - 1;
      }

      if (blankCount > 0) {
        for (var i = 0; i < blankCount; i++) {
          str =
            str.substring(0, (i + 1) * 4 + i) +
            " " +
            str.substring((i + 1) * 4 + i);
          // console.log(str);
        }
      } else {
        console.log(
          "La cadena de entrada no tiene más de 4 dígitos, no es necesario agregar espacios"
        );
      }
      // Volver

      return str;
    },

    send() {

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      return this.cardService();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.form.number.length === 16) {
        this.disabled = !this.disabled;
        this.cardService();
      } else {
        this.form.number = "";
      }
    },

    async cardService() {
      var Base_URL = `https://us-central1-dtc-2012.cloudfunctions.net`;
      // var Base_URL = `http://localhost:5001/dtc-2012/us-central1`;

      const plan = JSON.parse(localStorage.getItem('p'))
      const formStep1 = JSON.parse(localStorage.getItem('formStep1'))
      const formStep2 = JSON.parse(localStorage.getItem('formStep2'))

      // console.log(plan);

      const userData = {
        ...formStep1,
        ...formStep2,
        catastrophic: plan.cat
      }

      const planData = JSON.parse(localStorage.getItem('planData') || null)


      try {

        const amount = plan.amount;
        const idPlazo = this.form.dues != 24 ? 1 : this.form.duesSM;
        const cuotas = this.form.dues != 24 ? this.form.dues : 1;
        const conductoPago = this.form.dues == 24 ? 0 : this.form.dues == 1 ? 1 : 2;
        const getPlanAmount = (amountTotal, cuotas) => {
          if (cuotas == 12) {
            return Number(amountTotal / 12).toFixed(2);
          } else if (cuotas == 2) {
            return Number(amountTotal / 2).toFixed(2);
          } else if (cuotas == 4) {
            return Number(amountTotal / 4).toFixed(2);
          } else {
            return amountTotal;
          }
        }

        const body = {
          ccs: Number(this.form.cvc),
          anioExpiracion: Number(this.form.year),
          cuenta: Number(this.form.number),
          idPlazo: idPlazo,
          cuotas: cuotas,
          conductoPago: conductoPago,
          mesExpiracion: this.form.month,
          titular: this.form.cardholder,
          // Username: "ws.affinity",
          currency: "MXN",
          monto: Number(getPlanAmount(amount, cuotas)),
          userData,
          quoteId: planData.quoteId,
          catastrophic: plan.cat,
          companyId: "RELIANCE"
        }


        //console.log('body',body);

        this.$swal.fire({
          title: 'Procesando pago',
          html: 'Favor espere mientras verificamos sus datos',
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })


        // return this.$swal('Pago recibido con exito!!');

        this.loading = true

        const { data } = await axios.post(`${Base_URL}/AIG_HOGAR_PAYMENT_EMILI_PROCESS_PAYMENT_V3`, body)

        this.$swal.close()
        //console.log(data);

        if (!data.status) {

          const message = data.message

          this.$gtag.event('try_payment_error', {
            'event_category': 'quote',
            'event_label': message,
            'value': 1
          })

          this.$swal.fire(
            "Oops!",
            message,
            "error",
          );
          this.policyNumber = ''
        } else {
          this.policyNumber = data.policyData.policyNumber;
          this.visible = true;


          this.$gtag.purchase({
            "transaction_id": '',
            "affiliation": "Policy issued",
            "value": plan.amount
          })

          this.$gtag.event('try_payment_completed', {
            'event_category': 'quote',
            'event_label': 'Payment completed',
            'value': 1
          })
        }



        this.loading = false



        //  this.$bvToast.show("Pago_exitoso_noty");
        // if (response.status != 200) {
        //   this.$bvToast.show("Pago_exitoso_noty");
        // } else {
        //   this.$bvToast.show("Pago_error_noty");
        // }


      } catch (error) {
        console.log(error.message);
        this.loading = false

        this.$swal.close()

        this.$gtag.event('try_payment_error', {
          'event_category': 'quote',
          'event_label': 'Unexpected error',
          'value': 1
        })

        this.$swal.fire(
          "Oops!",
          "Problemas de conexión. Se ha excedido el tiempo límite de la petición o hubo un error al conectar con el banco, por favor intente nuevamente.",
          "error",
        );
        // this.$bvToast.show("Pago_error_noty");
      }


    },

    selectYear() {

      this.optionsYear.push({ value: null, id: 'Año' })
      for (let i = 2022; i <= 2050; i++) {
        this.optionsYear.push({ value: i, id: String(i) });
      }
    },

    selectMonth() {

      this.optionsMonth.push({ value: null, id: 'Mes' })
      for (let i = 1; i <= 12; i++) {

        this.optionsMonth.push({ value: i, id: String(i) });
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.selectYear();
    this.selectMonth();
  },
};
</script>

<style>
.rowing {
  border: 1px solid black;
}

.fex {
  font-size: 13px;
}

.card {
  -webkit-box-shadow: 13px 9px 18px -1px rgba(99, 99, 101, 0.4);
  box-shadow: 13px 9px 18px -1px rgba(97, 98, 99, 0.4);
}
</style>